import React, { useState, useEffect } from 'react';
import newRequest from "../../utils/newRequest";
import "./Newuser.css";

const Newuser = ({ currentUser }) => {
  const [newUsers, setNewUsers] = useState([]);
  
  useEffect(() => {
    const fetchNewUsers = async () => {
      try {
        const response = await newRequest.get('/newuser/getAllNewUsers');
        console.log(response);
        setNewUsers(response.data || []);
      } catch (error) {
        console.error('Error fetching new users:', error);
      }
    };

    fetchNewUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await newRequest.delete(`/newuser/${id}`);
      // After successful deletion
      setNewUsers((prevUsers) => prevUsers.filter(user => user._id !== id));
      // Optionally, refetch the new users
      const response = await newRequest.get('/newuser/getAllNewUsers');
      setNewUsers(response.data || []);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleApprove = async (user) => {
    const userData = {
      username: user.username,
      email: user.email,
      password: user.password || 'defaultPassword123', // Handle the password securely
      admin: user.admin,
      phone: user.phone
    };

    try {
      await newRequest.post("/auth/register", userData);
      console.log("User approved and registered as admin");
      handleDelete(user._id); // After approval, delete from the new users list
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };

  return (
    <div className='myGigs'>
      <div className='container'>
        <div className="title">
          <h1>New Employee</h1>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Admin</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {newUsers.map(user => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.phone}</td>
                <td>{user.admin ? "Admin" : "Not Admin"}</td>
                <td>
                  <div className="btn">
                    <button className="approve" onClick={() => handleApprove(user)}>Approve</button>
                    <img
                      className="delete"
                      src="./img/delete.png"
                      alt="Delete"
                      onClick={() => handleDelete(user._id)} // Ensure 'user._id' is passed here
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Newuser;
