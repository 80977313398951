import React from "react";
import "./Home.css";
import Featured from "../../components/featured/Featured";

function Home() {
  return (
    <div className="home">
      <Featured />
    </div>
  );
}

export default Home;
