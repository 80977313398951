import React from "react";
import { Link } from "react-router-dom";
import "./Reqtutor.css";
import getCurrentUser from "../../utils/getCurrentUser";
import newRequest from "../../utils/newRequest";

function Reqtutor() {
  const currentUser = getCurrentUser();
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Fetch data on component mount
  React.useEffect(() => {
    const fetchReqtutors = async () => {
      try {
        const response = await newRequest.get(`/reqtutors`);
        setData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchReqtutors();
  }, []);

  // Handle delete request
  const handleDelete = async (id) => {
    if (!currentUser.admin) {
      alert("You are not authorized to delete this application.");
      return;
    }
    try {
      await newRequest.delete(`/reqtutors/${id}`);
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (err) {
      console.error("Error deleting Reqtutor:", err);
    }
  };

  // Handle approve request
  const handleApprove = async (reqtutor) => {
    try {
      await newRequest.post("/gigs", reqtutor);
      console.log("Tutor approved and saved as a gig:", reqtutor);
      await handleDelete(reqtutor._id);  // Ensure handleDelete is awaited
    } catch (err) {
      console.error("Error approving tutor:", err);
    }
  };

  return (
    <div className="myGigs">
      {isLoading ? (
        "Loading..."
      ) : error ? (
        "An error occurred while fetching data."
      ) : (
        <div className="container">
          <div className="title">
            <h1>Applications</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Price</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((reqtutor) => (
                <tr key={reqtutor._id}>
                  <td>{reqtutor.title}</td>
                  <td>{reqtutor.shortTitle}</td>
                  <td>{reqtutor.price}</td>
                  <td>{reqtutor.desc}</td>
                  <td>
                    <div className="btn">
                      <button className="approve" onClick={() => handleApprove(reqtutor)}>Approve</button>
                      <img
                        className="delete"
                        src="./img/delete.png"
                        alt="Delete"
                        onClick={() => handleDelete(reqtutor._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Reqtutor;
